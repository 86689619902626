import type { LinkProps as RemixLinkProps } from "@remix-run/react";
import { Link as RemixLinkComponent } from "@remix-run/react";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import { forwardRef, type HTMLAttributes } from "react";

import { cn } from "~/utils/classnames";

const headingStyles = cva("", {
  variants: {
    size: {
      sm: "text-sm font-semibold",
      base: "text-base font-semibold", // h6
      lg: "text-lg font-semibold tracking-tight", // h5
      xl: "text-xl font-semibold tracking-tight", // h4
      "2xl": "text-2xl font-semibold tracking-tight", // h3
      "3xl": "text-3xl font-semibold tracking-tight", // h2
      "4xl": "text-3xl font-bold tracking-tight lg:text-4xl", // h1
      "5xl": "text-5xl font-bold tracking-tight",
      "6xl": "text-6xl font-bold tracking-tight",
    },
    muted: {
      true: "text-grey-500",
    },
    uppercase: {
      true: "uppercase",
    },
  },
});

interface HeadingProps
  extends HTMLAttributes<HTMLHeadingElement>,
    VariantProps<typeof headingStyles> {
  as: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}

export const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(function Heading(
  { as, size, muted, uppercase, className, children, ...props },
  ref
) {
  const Comp = as;

  const sizes = {
    h1: "4xl",
    h2: "3xl",
    h3: "2xl",
    h4: "xl",
    h5: "lg",
    h6: "base",
  } as const;

  return (
    <Comp
      ref={ref}
      className={cn(headingStyles({ size: size || sizes[as], muted, uppercase, className }))}
      {...props}
    >
      {children}
    </Comp>
  );
});

const textStyles = cva("", {
  variants: {
    size: {
      xs: "text-xs",
      sm: "text-sm",
      base: "text-base",
      lg: "text-lg",
      xl: "text-xl",
      "2xl": "text-2xl",
      "3xl": "text-3xl",
      "4xl": "text-4xl",
    },
    bold: {
      true: "font-semibold",
      false: "font-normal",
    },
    muted: {
      true: "text-grey-500",
    },
  },
});

interface TextProps
  extends HTMLAttributes<HTMLParagraphElement>,
    Omit<VariantProps<typeof textStyles>, "as"> {
  as?: "div" | "span" | "p" | "small" | "em" | "strong";
}

export const Text = forwardRef<HTMLParagraphElement, TextProps>(function Text(
  { as, size, bold, muted, className, children, ...props },
  ref
) {
  const Comp = as || "p";

  return (
    <Comp ref={ref} className={cn(textStyles({ size, bold, muted, className }))} {...props}>
      {children}
    </Comp>
  );
});

const linkStyles = cva("text-primary-700 hover:underline", {
  variants: {
    bold: {
      true: "font-semibold",
    },
    muted: {
      true: "text-primary-700/80",
    },
  },
});

interface LinkProps extends RemixLinkProps, VariantProps<typeof linkStyles> {}

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(function Link(
  { bold, className, children, ...props },
  ref
) {
  return (
    <RemixLinkComponent ref={ref} className={cn(linkStyles({ bold, className }))} {...props}>
      {children}
    </RemixLinkComponent>
  );
});
